import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import "@fontsource/eb-garamond"

const FeaturesStart = () => {
  return (
    <SectionContainer id="featuresstart">
      <SectionWrapper>
        <ColumnTwo>
          <StaticImage src="../../images/Section4.png" alt="Sec4" />
        </ColumnTwo>
        <ColumnOne>
          <SectionH1>SHOPPING MADE SMART</SectionH1>

          <SectionP>
            ZAJIYAH Carnival is your gateway to a smart shopping experience.
          </SectionP>
          <SectionP2>
            Introducing Smart Technologies for the first time in Pakistan, the
            Carnival shifts the paradigm of retail experience. For the first
            time in Pakistan customers will experience the true power of
            technology that is already revolutionizing experiences worldwide.
            The whole infrastructure is equipped with proprietary Bean-Web
            sensors and software that offer shoppers exciting deals right in
            their smartphones as they roam the Carnival. These smart systems
            also provide retailers a unique opportunity to showcase their best
            products for enhanced customers satisfaction. The result is premium
            shopping experience that brings together the joy of shopping
            alongside friends and family with the amazing deals and benefits of
            shopping online.
          </SectionP2>
        </ColumnOne>
      </SectionWrapper>
    </SectionContainer>
  )
}

export default FeaturesStart

const SectionContainer = styled.div`
  width: 100%;
  background: white;
  color: black;
  padding: 6rem 0rem;

  margin: auto;

  @media screen and (max-width: 960px) {
    margin-top: -80px;
  }
`
const SectionWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding: 1 1rem;
  grid-gap: 2.5rem;

  @media screen and (max-width: 960px) {
    grid-template-rows: 1fr;
  }
`
const ColumnOne = styled.div`
  padding: 0 0.2rem;
  order: 2;
  @media screen and (max-width: 960px) {
    padding: 0 1rem;
    float: left;
    order: 1;
  }
`
const ColumnTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 0.2rem;
  margin-top: -160px;
  order: 1;

  @media screen and (max-width: 960px) {
    padding: 0 1rem;
    float: right;
    order: 2;
    margin-top: 0px;
  }
`
const SectionH1 = styled.h1`
  font-family: "League Spartan", sans-serif;
  font-size: 3.5rem;
  font-weight: 600;
  color: #545454;
  text-align: center;
  letter-spacing: 3px;

  @media screen and (max-width: 960px) {
    text-align: center;
  }
  @media screen and (max-width: 460px) {
    font-size: 3rem;
  }
`

const SectionP = styled.p`
  color: #545454;
  font-size: 1.3rem;
  text-align: center;
  font-family: "EB Garamond";
  line-height: 1.7;
  font-weight: 500;
  @media screen and (max-width: 960px) {
    text-align: center;
  }
`

const SectionP2 = styled.p`
  color: #545454;
  font-size: 1.3rem;
  text-align: justify;
  text-align-last: center;
  font-family: "EB Garamond";
  line-height: 1.7;
  font-weight: 500;
  @media screen and (max-width: 960px) {
    text-align: center;
  }
`
