import React from "react"
import styled from "styled-components"
import { StaticImage as Image } from "gatsby-plugin-image"
import "@fontsource/encode-sans-expanded"
import { Link } from "gatsby"

const FeaturesEnd = () => {
  return (
    <SectionContainer id="featuresend">
      <SectionWrapper>
        <ColumnOne>
          <SectionH1>SETTING GREATER STANDARDS</SectionH1>
        </ColumnOne>
        <ColumnTwo>
          <Link to="/features">
            <Image src="../../images/I1.png" alt="I1" />
          </Link>
          <Link to="/features">
            <Image src="../../images/I2.png" alt="I2" />
          </Link>
          <Link to="/features">
            <Image src="../../images/I3.png" alt="I3" />
          </Link>
        </ColumnTwo>
        <ColumnTwo>
          <Link to="/features">
            <Image src="../../images/I4.png" alt="I4" />
          </Link>
          <Link to="/features">
            <Image src="../../images/I5.png" alt="I5" />
          </Link>
          <Link to="/features">
            <Image src="../../images/I6.png" alt="I6" />
          </Link>
        </ColumnTwo>
        <ColumnTwo>
          <Link to="/features">
            <Image src="../../images/I7.png" alt="I7" />
          </Link>
          <Link to="/features">
            <Image src="../../images/I8.png" alt="I8" />
          </Link>
          <Link to="/features">
            <Image src="../../images/I9.png" alt="I9" />
          </Link>
        </ColumnTwo>
        <ButtonWrap>
          <Link to="/visit">
            <ButtonThree>BOOK A VISIT</ButtonThree>
          </Link>
        </ButtonWrap>
      </SectionWrapper>
    </SectionContainer>
  )
}

export default FeaturesEnd

const SectionContainer = styled.div`
  width: 100%;
  background: white;
  color: black;
  padding: 1rem 0rem;
  margin-top: -4rem;

  @media screen and (max-width: 960px) {
    margin-top: 0px;
  }
`
const SectionWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  padding: 1 1rem;
  grid-gap: 2.5rem;

  @media screen and (max-width: 960px) {
    grid-template-rows: 1fr;
  }
`
const ColumnOne = styled.div`
  padding: 0 0.2rem;

  @media screen and (max-width: 960px) {
    padding: 0 1rem;
  }
`
const ColumnTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 1 0.2rem;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);

  &:hover {
    background: white;
    cursor: pointer;
    color: #e1ae44;
    transform: translateY(-2px);
    box-shadow: 0px 10px 15px rgba(225, 174, 68, 0.6);
    border: 1px solid #e1ae44;
  }

  @media screen and (max-width: 960px) {
    margin: 0 1rem;
  }
  @media screen and (max-width: 768px) {
    margin: 0 1rem;
    grid-template-columns: 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
  }
`
const SectionH1 = styled.h1`
  font-family: "League Spartan", sans-serif;
  font-size: 3.5rem;
  font-weight: 600;
  color: #545454;
  text-align: center;
  letter-spacing: 3px;

  @media screen and (max-width: 960px) {
    text-align: center;
  }
  @media screen and (max-width: 460px) {
    font-size: 3rem;
  }
`
const ButtonWrap = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
`

const ButtonThree = styled.button`
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #e1ae44;
  white-space: nowrap;
  padding: 1rem 2rem;
  color: black;
  max-width: 200px;
  min-width: 100px;
  font-size: 16px;
  font-family: "Encode Sans Expanded", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 1px;
  border: none;

  transition: 0.3s !important;
  border-radius: 2rem;

  &:hover {
    background: white;
    color: #e1ae44;
    transform: translateY(-2px);
    box-shadow: 0px 10px 15px rgba(225, 174, 68, 0.6);
    border: 1px solid #e1ae44;
  }
`
