import React from "react"
import styled from "styled-components"
import { FaTimes } from "react-icons/fa"
import { Link } from "gatsby"
import "@fontsource/encode-sans-expanded"

const Sidebar2 = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <ClosedIcon />
      </Icon>
      <Wrapper>
        <Menu>
          <SidebarLink to="/">About</SidebarLink>

          <SidebarLink to="/features">Features</SidebarLink>

          <SidebarLink to="/updates">Updates</SidebarLink>

          <SidebarLink to="/partners">Partners</SidebarLink>

          <SidebarLink to="/careers">Careers</SidebarLink>

          <SidebarLink to="/contact">Contact Us</SidebarLink>
        </Menu>
      </Wrapper>
    </SidebarContainer>
  )
}

export default Sidebar2

const SidebarContainer = styled.aside`
  display: none;

  @media screen and (max-width: 900px) {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #1b2227;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? "100%" : "0%")};
    top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
    color: #fabf49;
  }
`

const ClosedIcon = styled(FaTimes)`
  color: #fabf49;
`

const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`

const Wrapper = styled.div`
  color: #fabf49;
`

const SidebarLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #fabf49;
  cursor: pointer;
  font-family: "Encode Sans Expanded", sans-serif;

  &:hover {
    color: white;
    transition: 0.2s ease-in-out;
  }
`

const Menu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px);
  text-align: center;

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(6, 60px);
  }
`
